/* eslint-disable react-hooks/rules-of-hooks */
export type { NWTEvent } from './nw-tracking-types';

export type {
  Events,
  OperationalTrackingEvent,
  OperationalTrackingEventWithGenericData,
  PageViewTrackingEvent,
  InteractionTrackingEvent,
  TrackingEvent,
} from './types';

import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import snakeCaseKeys from 'snakecase-keys';

import {
  useViewerData,
  useExperimentTrackingData,
  useExperiments,
} from '@xing-com/hub';
import ttt from '@xing-com/ticktricktrack';

import { SCHEMA_VERSION as NWT_SCHEMA_VERSION } from './nw-tracking-types';
import type {
  OperationalTrackingEventWithGenericData,
  UseTrackingReturn,
  TrackingEvent,
  UserId,
} from './types';

export const SCHEMA_VERSION = '1.3.0';

type milliseconds = number;

const DELAY: milliseconds = 10000; // ms
export const ENDPOINT = '/api/quotable-blimp/operational';

const MOBILE_BREAKPOINT = 739;
const isMobile = (): boolean =>
  typeof globalThis.window !== 'undefined' &&
  globalThis.window.innerWidth <= MOBILE_BREAKPOINT;

const wait = (delay: milliseconds): Promise<void> =>
  new Promise((resolve) => {
    setTimeout(resolve, delay);
  });

// Nicolas Storl: this function is only called once within this file.
// The parameter is always stringified so it doesn't make sense to use the UserId type here.
const unscrambleId = (
  id: UserId
  // if user.id is a number, return it. Otherwise, parse and return the int part of it
): number => (typeof id === 'string' ? parseInt(id.split('.')[0]) : id);

let operationalTrackingEvents: OperationalTrackingEventWithGenericData[] = [];

export const useTracking = <T,>(page?: string): UseTrackingReturn<T> => {
  const doSend = (): void => {
    if (operationalTrackingEvents.length === 0) return;

    const eventsToSend = operationalTrackingEvents.map((ev) => {
      const snakeCased = snakeCaseKeys(ev, {
        exclude: ['ODT-Schema-Version'] as const,
      });

      // the version is added after the snake-casing, because of
      // the special format
      snakeCased['headers'] = {
        'ODT-Schema-Version': SCHEMA_VERSION,
      };
      return snakeCased;
    });

    globalThis.navigator.sendBeacon(ENDPOINT, JSON.stringify(eventsToSend));
    operationalTrackingEvents = [];
  };

  const sendOperationalTracking = useCallback(
    async (
      ev: OperationalTrackingEventWithGenericData,
      immediately = false
    ): Promise<void> => {
      operationalTrackingEvents.push(ev);

      if (immediately) {
        doSend();
      } else {
        await wait(DELAY);
        doSend();
      }
    },
    []
  );

  const viewerData = useViewerData();
  const userId = viewerData?.data?.user?.id;
  const unscrambledUserId = unscrambleId(`${userId}`);
  const PropMemberships = viewerData?.data?.propMemberships;

  const intl = useIntl();
  const locale = intl.locale;

  const { PropExperiment, PropExperimentInfo } = useExperimentTrackingData();
  const experiments = useExperiments();

  const isExperimentsLoading = experiments?.loading;
  const isUserLoading = viewerData?.loading;

  const isLoading = isUserLoading || isExperimentsLoading;

  const track = useCallback(
    (payload: TrackingEvent<T>): void => {
      if (isLoading) {
        return;
      }

      if (payload.type === 'operational') {
        const event: OperationalTrackingEventWithGenericData = payload;

        if (page) {
          event.context = {
            ...event.context,
            page,
          };
        }

        delete event.type;
        event.eventTimestamp = Date.now();
        event.client = {
          userAgent: globalThis.navigator.userAgent,
          channel: isMobile() ? 'webm' : 'web',
        };

        if (userId) {
          event.login = {
            ...(event.login || {}),
            // id that is handed over has precedence
            userId: event.login?.userId ?? userId,
          };
        }

        event.trackingTokens = payload.trackingTokens || [];
        event.additionalInfo = payload.additionalInfo || {};
        const { immediately = false, ...sendableEvent } = event;

        sendOperationalTracking(sendableEvent, immediately);

        // fire and forget, so no async here ...
      } else if (payload.type === 'pageview') {
        // adobe page view events
        const { type, channel, page, contextProps, ...rest } = payload;
        const brazeParams = userId
          ? {
              brazeUserId: `${unscrambledUserId}`,
            }
          : {};

        ttt.pageview(
          channel,
          page,
          {
            PropExperiment,
            PropExperimentInfo,
            ...brazeParams,
            ...rest,
          },
          contextProps
        );
      } else if (payload.type === 'interaction') {
        // adobe interaction events
        const { type, event, ...rest } = payload;
        if (userId) {
          // @ts-expect-error let's fix it later :)
          rest.brazeUserId = `${unscrambledUserId}`;
        }
        ttt.event(event, rest);
      } else if (payload.type === 'nwt') {
        const { type, ...event } = payload;

        event.channel = isMobile() ? 'webm' : 'web';
        event.sdkName = 'crate';
        event.applicationLanguage = locale;

        if (PropExperiment) {
          event.experiment = PropExperiment;
        }

        if (PropMemberships) {
          event.subscriptions = PropMemberships;
        }

        if (!event.userId && userId) {
          // TODO: check why the UserId can be a string or a number
          event.userId = `${userId}`;
        }

        // Overwrite the schema version with the current one defined by the nwt tracking lib
        event.schemaVersion = NWT_SCHEMA_VERSION;

        ttt.nwtEvent(snakeCaseKeys(event));
      }
    },
    [
      PropExperiment,
      PropExperimentInfo,
      PropMemberships,
      isLoading,
      page,
      sendOperationalTracking,
      unscrambledUserId,
      userId,
      locale,
    ]
  );

  return { track };
};
